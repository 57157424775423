<template>
	<div class="my-3 p-3 shadow-sm border">
		<div class="d-flex w-100">
			<span
				class="mx-2 p-2 rounded d-flex align-items-center col-2"
				:class="{ border: ShowFilters, 'bg-light': ShowFilters }"
				@click="
					() => {
						ShowFilters = !ShowFilters;
					}
				"
				style="cursor: pointer"
			>
				<i class="fa fa-filter" aria-hidden="true"></i>
				<span class="mx-2" style="width: 15vh"
					>{{ $t("inventory_search_bar_filter_button") }}
				</span>
			</span>
			<div class="col-10 d-flex align-items-center px-2">
				<div class="col-md-8 col-sm-12 border d-flex">
					<input
						type="tel"
						placeholder="Enter Ordered Phone Number"
						style="border: none"
						class="p-2 w-100"
						v-model="queryText"
						@keydown.enter="SearchOrder"
					/>
					<button
						class="btn border theme-background-primary text-light p-2"
						@click="SearchOrder"
					>
						{{ $t("inventory_search_bar_button_name") }}
					</button>
				</div>
			</div>
		</div>
		<div
			class="my-2 d-flex"
			:class="{ 'd-flex': ShowFilters, 'd-none': !ShowFilters }"
		>
			<!-- Status -->
			<div class="col-4 p-3">
				<strong>Status</strong>
				<div>
					<select
						class="p-2 my-2"
						style="width: 100%; height: 100%; border: 1px solid lightgrey"
						v-model="queryStatus"
					>
						<option disabled selected :value="null">
							Filter with Order Status
						</option>
						<option :value="0">Pending</option>
						<option :value="1">Delivering</option>
						<option :value="2">Delivered</option>
					</select>
				</div>
			</div>
			<!-- Payments Status -->
			<div class="col-4 p-3">
				<strong>Payment Status</strong>
				<div>
					<select
						class="p-2 my-2"
						style="width: 100%; height: 100%; border: 1px solid lightgrey"
						v-model="queryPaymentStatus"
					>
						<option disabled selected :value="null">
							Filter with Payment Status
						</option>
						<option :value="true">Paid</option>
						<option :value="false">Not Paid</option>
					</select>
				</div>
			</div>
			<!-- Branch -->
			<div class="col-4 p-3">
				<strong>Branch</strong>
				<div>
					<select
						class="p-2 my-2"
						style="width: 100%; height: 100%; border: 1px solid lightgrey"
						v-model="queryBranch"
					>
						<option disabled selected :value="null">Filter with Branch</option>
						<option :value="0">Summit</option>
						<option :value="1">Nani</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	// ShowFilters: false,
	// queryText: "",
	// queryStatus: null,
	// queryPaymentStatus: null,
	// queryBranch: null,
	props: {
		ShowFilters: {
			type: Boolean,
			default: false,
		},
		queryText: {
			type: Boolean,
			default: false,
		},
		queryStatus: {
			type: Boolean,
			default: false,
		},
		queryPaymentStatus: {
			type: Boolean,
			default: false,
		},
		queryBranch: {
			type: Boolean,
			default: false,
		},
		queryType: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style lang=""></style>
