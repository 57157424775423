<template>
  <div class="theme-dashboard-view">
    <!-- Dashboard Title -->
    <h1>{{ $t("order_home_title") }}</h1>

    <!-- Filters Button-->
    <div>
      <span
        class="p-2 rounded d-flex align-items-center"
        :class="{ border: ShowFilters, 'bg-light': ShowFilters }"
        @click="
          () => {
            ShowFilters = !ShowFilters;
          }
        "
        style="cursor: pointer"
      >
        <i class="fa fa-filter" aria-hidden="true"></i>
        <span class="mx-2" style="width: 15vh"
          >{{ $t("inventory_search_bar_filter_button") }}
        </span>
      </span>
    </div>

    <!-- Filters -->
    <div
      class="justify-content-between p-3 border user-select-none"
      :class="{ 'd-flex': ShowFilters, 'd-none': !ShowFilters }"
    >
      <div class="px-2 w-100">
        <p class="font-weight-bold mx-2 my-1 p-0">
          {{ $t("order_home_filter_type") }}
        </p>
        <ul class="list-unstyled">
          <!-- <li
						class=""
						:class="type == 1 ? 'active' : 'inactive'"
						@click="clickType(1)"
					>
						Upcoming
					</li> -->

          <li
            class="px-2 py-1"
            :class="type == 0 ? 'active' : 'inactive'"
            @click="clickType(0)"
          >
            {{ $t("order_home_filter_type_on_site") }}
          </li>
          <li
            class="px-2 py-1"
            :class="type == 1 ? 'active' : 'inactive'"
            @click="clickType(1)"
          >
            {{ $t("order_home_filter_type_delivery") }}
          </li>
        </ul>
      </div>

      <div class="px-2 w-100">
        <p class="font-weight-bold mx-2 my-1 p-0">
          {{ $t("order_home_filter_status") }}
        </p>
        <ul class="list-unstyled">
          <li
            class="px-2 py-1"
            :class="status == 0 ? 'active' : 'inactive'"
            @click="clickStatus(0)"
          >
            {{ $t("order_home_filter_status_pending") }}
          </li>
          <li
            class="px-2 py-1"
            :class="status == 1 ? 'active' : 'inactive'"
            @click="clickStatus(1)"
          >
            {{ $t("order_home_filter_status_delivering") }}
          </li>
          <li
            class="px-2 py-1"
            :class="status == 2 ? 'active' : 'inactive'"
            @click="clickStatus(2)"
          >
            {{ $t("order_home_filter_status_delivered") }}
          </li>
        </ul>
      </div>

      <div class="px-2 w-100"></div>
      <div class="px-2 w-100"></div>
    </div>

    <!-- Loading Indicator -->
    <div class="d-flex justify-content-center">
      <LoadingIndicator :active="loading" />
    </div>
    <div v-show="load_error">
      <div class="justify-content-center text-center text-danger my-4">
        <h4>{{ load_error }}</h4>
      </div>
    </div>

    <!-- Main Content -->
    <div class="m-2 p" v-show="!loading">
      <!-- Shows that the table is being updated live -->
      <div
        class="d-flex justify-content-between"
        :class="
          this.type == null && this.status == null && this.current_page == 1
            ? 'border-bottom border-3  border-danger px-3 text-danger'
            : 'no-border text-muted'
        "
        @click="
          () => {
            this.type = null;
            this.status = null;
            this.current_page = 1;
            this.setOrders(1);
          }
        "
        style="cursor: pointer"
      >
        <div class="d-flex">
          <span class="d-flex align-items-center"
            ><i class="fa fa-circle" style="font-size: 10px"></i
          ></span>

          <span class="font-weight-bold mx-1">LIVE</span>
        </div>
        <div>
          Last updated:
          {{
            this.type == null && this.status == null && this.current_page == 1
              ? formatDate(update_time)
              : formatDate(last_updated_time)
          }}
        </div>
      </div>

      <div>
        {{ JSON.stringify(params, null, 4) }}
      </div>
      <!-- Order Table Data -->

      <Table
        :rows="order_data"
        :hover="true"
        :contain="[
          'id',
          'user_phone_number',
          'type',
          'status',
          'created_at',
          'branch',
        ]"
        :rowClickFunction="clickOrder"
      >
        <template v-slot:id-title>{{ $t("order_home_table_id") }}</template>
        <template v-slot:type-title>{{ $t("order_home_table_type") }}</template>
        <template v-slot:user_phone_number-title>{{
          $t("order_home_table_phone_number")
        }}</template>
        <template v-slot:status-title>{{
          $t("order_home_table_status")
        }}</template>
        <template v-slot:gross_price-title>{{
          $t("order_home_table_total_price")
        }}</template>
        <template v-slot:user_phoneNumber-title>{{
          $t("order_home_table_phone_number")
        }}</template>
        <template v-slot:created_at-title>{{
          $t("order_home_table_created_at")
        }}</template>
        <template v-slot:branch-title>
          {{ $t("order_home_table_branch") }}
        </template>

        <!-- Type Formatting -->
        <template v-slot:type="params">
          <div>
            <span v-show="params.row.type == 0">{{
              $t("single_order_type_on_site")
            }}</span>
            <span v-show="params.row.type == 1">{{
              $t("single_order_type_delivery")
            }}</span>
          </div>
        </template>

        <!-- Branch Formatting -->
        <template v-slot:branch="params">
          <div>
            <span v-show="params.row.branch == 0">{{
              $t(
                getBranchNameKey(params.row.id, new Date(params.row.created_at))
              )
            }}</span>
            <span v-show="params.row.branch == 1">{{ $t("nani") }}</span>
          </div>
        </template>

        <!-- Status Formatting -->
        <template v-slot:status="params">
          <div>
            <span
              style="border-radius: 5px"
              class="p-1 bg-warning text-light"
              v-show="params.row.status == 0"
              >{{ $t("order_home_table_status_pending") }}</span
            >
            <span
              style="border-radius: 5px"
              class="p-1 bg-primary text-light"
              v-show="params.row.status === 1"
              >{{ $t("order_home_table_status_delivering") }}</span
            >
            <span
              style="border-radius: 5px"
              class="p-1 bg-success text-light"
              v-show="params.row.status === 2"
              >{{ $t("order_home_table_status_delivered") }}</span
            >
          </div>
        </template>

        <!-- Created At Date for -->
        <template v-slot:created_at="params">
          <span>{{
            new Date(params.row.created_at).toLocaleDateString("en-UK", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            })
          }}</span>
        </template>

        <!-- Items Formatting -->
        <!-- <template v-slot:items="params">
					<div>
						{{ params.row.items.length }}
						Items
					</div>
				</template> -->
      </Table>
    </div>
    <div
      class="text-danger text-center"
      v-show="is_loaded && order_data.length == 0"
    >
      <h3>Orders cannot be found</h3>
    </div>
    <!-- Page navigator -->
    <div
      class="d-flex justify-content-center p-2"
      v-if="!loading && order_data.length != 0"
    >
      <PageNav
        :pages="pages"
        :current_page="current_page"
        @click-page="setOrders"
        @next-page="nextPage"
        @prev-page="prevPage"
        @goto-first="gotoFirstPage"
        @goto-last="gotoLastPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import SearchMenu from "@/components/SearchMenu.vue";
import Table from "@/components/Table.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";

//Services
import OrderService from "@/services/order.js";

export default {
  name: "DashboardOrders",
  components: {
    SearchMenu,
    Table,
    LoadingIndicator,
    PageNav,
  },
  data() {
    return {
      loading: false,
      load_error: "",
      last_updated_time: null,
      type: null,
      status: null,
      order_data: [],
      current_page: 1,
      ShowFilters: false,
      pages: 0,
      dateBeforeBranchShift: new Date(2023, 7, 17),
    };
  },
  methods: {
    clickOrder(index) {
      console.log("Click Order:", index);
      this.$router.push("/orders/" + this.order_data[index].id);
    },
    setOrders(page) {
      // this.loading = true;
      this.load_error = "";
      this.last_updated_time = new Date();

      OrderService.getOrders(page, this.type, this.status).then(
        (response) => {
          console.log(response.data.data.orders);
          this.loading = false;
          this.current_page = page;
          this.order_data = response.data.data.orders;
          this.pages = response.data.data.total_pages;
        },
        (error) => {
          this.loading = false;
          console.log("FAIL");
          this.load_error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    // SearchOrder() {
    // 	this.$router.push({
    // 		name: "OrderSearch",
    // 		params: {
    // 			query_text: utils.methods.changePhoneNumberFormat(this.queryText)
    // 				? utils.methods.changePhoneNumberFormat(this.queryText)
    // 				: null,
    // 			query_status: this.queryStatus,
    // 			query_payment_status: this.queryPaymentStatus,
    // 			query_branch: this.queryBranch,
    // 		},
    // 	});
    // },
    clickType(to) {
      if (to == this.type) {
        this.type = null;
        this.setOrders(1);
      } else {
        this.type = to;
        this.setOrders(this.current_page);
      }
    },
    clickStatus(to) {
      if (to == this.status) {
        this.status = null;
        this.setOrders(1);
      } else {
        this.status = to;
        this.setOrders(this.current_page);
      }
    },

    // Page Navigation
    nextPage() {
      if (this.current_page !== this.pages) {
        this.setOrders(this.current_page + 1);
      }
    },
    prevPage() {
      if (this.current_page !== 1) {
        this.setOrders(this.current_page - 1);
      }
    },
    gotoFirstPage() {
      if (this.current_page !== 1) {
        this.setOrders(1);
      }
    },
    gotoLastPage() {
      if (this.current_page !== this.pages) {
        this.setOrders(this.pages);
      }
    },

    /**
     * Represents a book.
     * @constructor
     * @param {number} id - The id of the order.
     * @param {Date} orderCreatedAt - The created_at of the order.
     */
    getBranchNameKey(id, orderCreatedAt) {
      console.log("Order Id", id);
      console.log(orderCreatedAt.getTime());
      console.log(this.dateBeforeBranchShift.getTime());
      // console.log(
      //   "Date differential",
      //   orderCreatedAt.getTime() - this.dateBeforeBranchShift.getTime()
      // );
      if (orderCreatedAt.getTime() - this.dateBeforeBranchShift.getTime() < 0) {
        return "prevAmoudi";
      } else {
        return "amoudi";
      }
    },
    formatDate(date) {
      if (date == null) {
        return "";
      }
      return date.toLocaleDateString("en-UK", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        day: "numeric",
        weekday: "long",
        month: "long",
        year: "numeric",
      });
    },
  },
  computed: {
    ...mapGetters({
      new_orders: "orders/newOrders",
      update_time: "orders/lastUpdate",
      order_first_page: "orders/firstPage",
    }),
  },
  mounted() {
    // this.setOrders(this.current_page);
    // Updates the last time user has seen the live orders
    if (this.order_data.length == 0) {
      this.loading = true;
    }
    this.$store.dispatch("orders/updateLastCheck", new Date());
  },
  watch: {
    // the values need to be updated for the new orders to be seen
    // only update if on the first page and no filters have been set
    order_first_page: {
      immediate: true,
      handler(first_page) {
        // Set the order table to new updated data if on first page and no filters
        if (
          this.type == null &&
          this.status == null &&
          this.current_page == 1
        ) {
          console.log("First page", first_page);
          if (first_page.orders.length > 0) {
            this.loading = false;
          }
          this.order_data = first_page.orders;
          this.pages = first_page.pages;
        }
      },
    },
  },
};
</script>

<style>
.active {
  border: 1px black solid;
  border-radius: 5px;
}

.inactive {
  color: grey;
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 900;
}

.pills {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.pills > li {
  border: 1px grey solid;
  color: grey;
  margin: 0px 10px;
  border-radius: 15px;
  padding: 5px;
  cursor: pointer;
}

.pills .active {
  background: orange;
  color: black;
  font-weight: bold;
}
</style>
